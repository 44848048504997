import "../styles/main.scss";
import ReactDOM from "react-dom";
import React from "react";
import Blog from "./_blog";
import pagination from "./_pagination";

document.addEventListener("DOMContentLoaded", () => {
    handleNavSearch();
    /* Homepage - Add pagination and arrows to the blue overlay div so it's bounded there */
    if (document.body.classList.contains("home")) {
        let interval = setInterval(() => {
            if (document.querySelector(".owl-buttons") != null) {
                clearInterval(interval);
                jQuery(".home-slider .owl-buttons").insertAfter(jQuery(".overlay"));
                jQuery(".owl-pagination").insertAfter(jQuery(".overlay"));
            }
        });
    }
    if (document.body.classList.contains("page-template-index")) {
        console.log("in index");
        let interval = setInterval(() => {
            if (document.querySelector('#blog-root') != null) {
                clearInterval(interval);
                ReactDOM.render(<Blog />, document.querySelector("#blog-root"));
                //handleFilterFix();
            }
        });
    }
    if (document.body.classList.contains("page-template-leadership2")) {
        jQuery('.filters li a').click(e => {
            setTimeout(() => {
                if (jQuery(e.currentTarget).attr('data-filter') == "*") {
                    jQuery(e.currentTarget).attr('data-filter', '.leadership')
                } else {
                    jQuery(e.currentTarget).attr('data-filter', '*');
                }
            }, 100)

        })
    }
    if (document.body.classList.contains("page-template-leadership2")) {
        pagination(16, '#pagination');
    }
});

function handleFilterFix() {
    setTimeout(() => {
        console.log("in handle filter fix");
        document
            .querySelectorAll(".media-boxes-drop-down:nth-child(2) .cat-item")
            .forEach((cat) => {
                cat.addEventListener("click", (e) => {
                    console.log("clicked filter");
                    document.querySelector(
                        ".media-boxes-drop-down:nth-child(2) .media-boxes-drop-down-header"
                    ).innerHTML = `${e.currentTarget.innerText}<i class="fas fa-sort-down"></i>`;
                });
            });
        document
            .querySelectorAll(".media-boxes-drop-down:nth-child(3) .cat-item")
            .forEach((cat) => {
                cat.addEventListener("click", (e) => {
                    console.log("clicked filter");
                    document.querySelector(
                        ".media-boxes-drop-down:nth-child(3) .media-boxes-drop-down-header"
                    ).innerHTML = `${e.currentTarget.innerText}<i class="fas fa-sort-down"></i>`;
                });
            });
    }, 2000);
}

function handleNavSearch() {
    function changeIconToX() {
        jQuery('.menu-item-9411 i').removeClass();
        jQuery('.menu-item-9411 i').addClass('fa-solid');
        jQuery('.menu-item-9411 i').addClass('fa-xmark');
    }
    function changeIconToSearch() {
        jQuery('.menu-item-9411 i').removeClass();
        jQuery('.menu-item-9411 i').addClass('fa-solid');
        jQuery('.menu-item-9411 i').addClass('fa-magnifying-glass');
    }
    function showSearch() {
        jQuery('.search-hover-wrapper').removeClass('hidden');
    }
    function hideSearch() {
        jQuery('.search-hover-wrapper').addClass('hidden');
    }

    jQuery('li.menu-item-9411').hover(() => {
        // Hover Starts
        changeIconToX();
        showSearch();
    }, () => {
        // Hover Ends
        if (jQuery('.search-hover-wrapper:hover').length == 0) {
            changeIconToSearch();
            hideSearch();
        }
    })
    jQuery('.search-hover-wrapper').hover(() => { }, () => {
        changeIconToSearch();
        hideSearch();
    });
}

const toggleMobileMenu = () => {
    document.querySelector("#mobile-menu").classList.toggle("hidden");
};
const handleHamburgerClick = () => {
    document.querySelector(".hamburger").classList.toggle("is-active");
    document.querySelector('body').classList.toggle('no-scroll');
    document.querySelector('html').classList.toggle('no-scroll');
    toggleMobileMenu();
};
const navLeave = (event) => {
    if (event.target.parentElement.children.length > 1) {
        if (!event.target.parentElement.children[1].matches(":hover")) {
            event.target.parentElement.children[1].classList.toggle("hidden");
        }
    }
};
const navEnter = (event) => {
    if (event.target.parentElement.children.length > 1)
        event.target.parentElement.children[1].classList.toggle("hidden");
};
const toggleMenu = (event) => {
    event.target.classList.toggle("hidden");
};

document.addEventListener("DOMContentLoaded", () => {
    if (window.innerWidth <= 768) {
        jQuery("ul.sub-menu").addClass("hidden");
        jQuery("section#mobile-menu .menu-item-has-children").hover(navEnter, navLeave);
        jQuery("button.hamburger").click(handleHamburgerClick);
    }
});