let postsPerPage = -1;

export default function pagination(_postsPerPage) {
    console.log('pagination starting...');
    let interval = setInterval(() => {
        if (document.querySelector('.media-box-loaded') != null) {
            clearInterval(interval);
            postsPerPage = _postsPerPage;
            main();
        }
    }, 500);
}

function main() {
    // how many pages should there be?
    // amount of posts / postsPerPage
    let amountOfPosts = document.querySelectorAll('.media-box').length;
    let amountOfPages = Math.ceil(amountOfPosts / postsPerPage);
    console.log("amount of posts:", amountOfPosts);
    console.log("amount of pages:", amountOfPages);
}
